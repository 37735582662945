<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" href="https://www.umohe.com" target="_blank">
              <v-icon size="16" color="primary">fa-home</v-icon>
            </v-btn>
          </template>
          <span>Go home</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body dash">
      <v-card flat>
        <v-overlay absolute :value="showing"></v-overlay>
        <v-toolbar flat>
          <v-toolbar-title class="earn_title">
            {{ blogList.title }}
            <div class="sub">
              {{ blogList.name }} {{ blogList.first_name }}
              {{ blogList.mid_name }}
              {{ blogList.last_name }}
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          {{ blogList.created | myDate }}
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="edited_template">
          <div v-html="blogList.details"></div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Restful from "@/services/RestFul";
import utils from "../../mixins/utils";

export default {
  mixins: [utils, WinScroll("position")],
  props: {
    id: { type: String },
  },
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: false,
          text: "Health Blogs",
          to: { name: "patient.blogs" },
        },
      ],
      blogList: {},
      showing: false,
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    list() {
      this.showing = true;
      let id = parseInt(this.id);
      console.log(id);
      let self = this;
      Restful.blogs
        .find(this.id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.blogList = response.data;
          }
          this.showing = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.showing = false;
        });
    },
  },
};
</script>
